<template>
  <div class="eyeEnvironment">
    <div class="title">用眼环境</div>
    <div class="container">
      <div class="environment">
        <img src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/eyeEnviro1.jpg">
        <p>350lux灯光</p>
      </div>
      <div class="environment">
        <img src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/eyeEnviro2.jpg">
        <p>350lux-500lux灯光</p>
      </div>
      <div class="environment">
        <img src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/eyeEnviro3.jpg">
        <p>500lux以上灯光</p>
      </div>
      <div class="environment">
        <img src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/eyeEnviro4.jpg">
        <p>2000lux以上接近户外环境</p>
      </div>
    </div>
    <button @click="go" style="display: none">返回</button>
  </div>
</template>

<script>
export default {
  name: "eyeEnvironment",
  data() {
    return {
    }
  },
  created() {},
  mounted() {},
  methods: {
    go() {
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped lang="scss">
.eyeEnvironment {
  width: 100%;
  height: 100%;
}
.title {
  line-height: 40px;
  font-weight: 600;
  font-size: 18px;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.environment {
  flex: 1;
}
.environment img {
  width: 90%;
}
button {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, -50%);
  padding: 10px 80px;
  background: transparent;
  border: 1px solid #333;
  color: #333;
  font-size: 18px;
}
</style>